// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: 'https://datexintegrationhub-configurationapi-prod.azurewebsites.net/configapi/',
  integrationHubConfigurationApiScopes: ['api://6fd69ce1-e9ca-4107-b5aa-3c9c7b08b137/.default'],
  legacyConnectorsConfigApiUrl: 'https://datex-file-watcher-configuration-api-prod.azurewebsites.net/',
  legacyConnectorsConfigApiScopes: ['api://542d189e-dd86-4316-a36a-77a779d17741/.default'],
  integrationHubApiUrl: 'https://api.datexintegrationhub.com/api/',
  integrationHubApiScopes: ['api://e577ba0d-6a9d-4e94-8a14-97f6b649dcd4/.default'],
  integrationHubLoggingApiUrl: 'https://datexintegrationhub-logging-api-prod.azurewebsites.net/hubapi/',
  integrationHubLoggingApiScopes: ['api://f86dfd87-958e-40ef-a5ec-0c90a79f3e96/.default'],
  datexAlertApiUrl: 'https://datex-alert-services-prod.azurewebsites.net/odata/',
  datexAlertApiScopes: ['api://95fda1ae-e7b7-4b30-9f73-a4a6ac3835c8/.default'],
  datexAlertRecommndedSolutionApiUrl: 'https://ca-alerts-suggestion-api.gentleflower-459ce483.eastus.azurecontainerapps.io/',
  datexAlertRecommndedSolutionApiScopes: ['api://6a655f5f-071d-45af-9264-e64ed357235a/.default'],
  kustoQueryApiUrl: 'https://ca-integrationhub-kustoquery-api.salmonflower-9c1fbcb8.eastus.azurecontainerapps.io/',
  kustoQueryApiScopes: ['api://b6ca991d-958f-4474-87bd-ee0e78f613e5/.default'],
  footPrintAdaptorApiUrl: 'https://datexintegrationhub-footprint-prod.azurewebsites.net/api/',
  footPrintAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  shopifyAdaptorApiUrl: 'https://datexintegrationhub-shopify-prod.azurewebsites.net/api/',
  shopifyAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  wooCommerceAdaptorApiUrl: 'https://datexintegrationhub-woocommerce-prod.azurewebsites.net/api/',
  wooCommerceAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  bigCommerceAdaptorApiUrl: 'https://datexintegrationhub-bigcommerce-prod.azurewebsites.net/api/',
  bigCommerceAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  grapcityLicenseKey: '*.wavelength.host,654486356437283#B1n2C8UTTzN6ckFWRvdGdHtkYT3CdxczSwE5M9djZTVEVTNEORRVN8pmd9JDUnJHOaZnaXl7QkRkNDFmUM3UNZVkcqNkWIN6doNkWtBVVNN5ZPlTR5l6QylFVS9mMrUEWQVUaGREaRd6MQpEZwEHRHtCVxEWa0BjQRFTW92UaThXMKdVSPZGdzMXVyFVV7QTZCBDSzJDd6R5Ur2CR8ZUO8YFTF34YhBTbhtWeL5mZXhGdp56YR9EelVna0pHT6c6aChmW8Y5N4cXSxE7RX3EeUpWQwRlT6BzcCdWdMtCczd5SmtiS8cDR7U6MjdEV4ZkI0IyUiwiI8MjQ5Q4Q9MjI0ICSiwSOwMTM4MTM4EjM0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIiMxgDN4ADIyEDNwMjMwIjI0ICdyNkIsICdz3GaugGdn9WZsVmdhdnLqIiOiMXbEJCLigXZ4FGRiojIh94QiwiIzgjM7MDN6UzM6gDN4UjNiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34TQTlUUSZXOMZ6NolTZu9WdNRXMFtWNkd4UWJHWad4SvMmVYhGRapXUWdWOQRjYmNDR8xmQiVnN5Z7LLdVZ9JkN9sCTxIzbyokZjFUWjJXQ9FVQ0R4NCB5NaZ6KatSUatybWNGVxrk33',
  ediLogicAppTriggerUrl: 'https://as2.datexintegrationhub.com',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-prod.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: '',
  ediPrivate: 'https://wavelength-edi-prod.eastus.cloudapp.azure.com/AS2Ingress'
};
